import React from 'react';
import ajax from '../../ajax';
import ActivityLoader from '../Common/ActivityLoader';
import TextInputWrap from '../Common/TextInputWrap';
import Toast from '../Common/Toast';


class PopUserPermission extends React.Component {
  state={
      loading:false,
      user:this.props.user,
      transactionList:false,
      permissionCode:'',
      toast:false,
      grantActive:true
  }

  onConfirm=()=>{
    this.setState({
        loading:true
    }, ()=>this.props.onConfirm())
  }

  componentDidMount()
  {
      this.requestRecord()
  }


  requestRecord=async()=>{
    const server_response=await ajax.listUserPermissions(this.state.user.user_id);
    if(server_response.status==="OK")
    {
      this.setState({
        transactionList:server_response.details.list
      })
    }else{
        this.setState({
            transactionList:'404'
          })
    }
  }

  onChangePermission=(event)=>{
    this.setState({
        permissionCode:event.target.value
    })
  }

  onGrantPermission=async(event)=>{
    event.preventDefault();
    const {permissionCode}=this.state;
    if(permissionCode.length>0)
    {
        this.setState({
            grantActive:false
        })
        const server_response=await ajax.grantAccess(this.state.user.user_id, permissionCode);
        this.setState({
            grantActive:true
        })
        if(server_response.status=="OK")
        {
            this.setState({
                toast:<Toast 
                message={server_response.details[0]} 
                onClose={()=>this.resetToast()}/>
            }, ()=>{
                this.requestRecord()
            })

        }else{
            this.setState({
                toast:<Toast 
                message={server_response.details[0]} 
                type={2} 
                onClose={()=>this.resetToast()}/>
            })
        }
        

    }else{

        this.setState({
            toast:<Toast 
            message={"Complete all fields and try again"} 
            type={2} 
            onClose={()=>this.resetToast()}/>
        })

    }

   
    
  }

  resetToast=()=>{
    this.setState({
        toast:false
    })
  }


  onClickRemoveAccess=async(access_code)=>{
      const server_response=await ajax.removeUserAccess(this.state.user.user_id, access_code);
      if(server_response.status==="OK")
      {
        this.setState({
            toast:<Toast 
            message={server_response.details[0]} 
            onClose={()=>this.resetToast()}/>
        }, ()=>{
            this.requestRecord()
        })

      }else{
        this.setState({
            toast:<Toast 
            message={server_response.details[0]} 
            type={2} 
            onClose={()=>this.resetToast()}/>
        })
      }
      
  }

  render(){
    const user =this.state.user
    const list=this.state.transactionList
      return (
        <div className="modal" id="modal_user_perms">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content tx-size-sm">
                <div className="modal-header">
                    <h6 className="modal-title">User Permissions</h6>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button">
                        <span aria-hidden="true">&times;</span>
                    </button> 
                    {this.state.toast}
                </div>
                <div className="modal-body tx-center pd-y-20 pd-x-20">
                  

                    <i className="fas fa-user-circle tx-60 tx-primary lh-1 mg-t-20 d-inline-block"></i>
                    <h4 className="tx-dark mg-b-5">{user.first_name + ' ' + user.last_name}</h4>
                    <p className="mg-b-10 mg-x-10">{user.username}</p>
                    <div class="input-group">
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Enter Permission"
                            onChange={this.onChangePermission}
                            />
                        <span class="input-group-append">
                            <input 
                                type="button" 
                                class="btn ripple btn-success" 
                                value="Grant"
                                onClick={this.onGrantPermission}
                                disabled={!this.state.grantActive}/>
                        </span>
                    </div>
                   
                  
                    <div className="user-manager scroll-widget border-top overflow-auto">
                            <div className="table-responsive">
                                <table className="table mg-b-0">
                                    <tbody>
                                    {list && list!=="404" && list.map((item, key)=>
                                        <tr key={key} 
                                            className="hot-tr"
                                        // onClick={()=>this.onClickRecord(item)}
                                        >
                                        
                                            <td className="bd-t-0">
                                                <h6 className="mg-b-0">{item.code}</h6>
                                                <small className="tx-11 tx-gray-500">{item.description}</small>
                                            </td>
                                            <td>
                                                <a class="main-contact-star" href="#">
                                                    <i 
                                                        class="fe fe-trash-2 mr-1 text-warning" 
                                                        title="Remove user access"
                                                        onClick={()=>this.onClickRemoveAccess(item.code)}></i>
                                                </a>
                                            </td>
                                        
                                        </tr>)}
                                    
                                    </tbody>
                                </table>
                                {!list && <ActivityLoader/>}
                                                        
                            </div>
                        </div>
       


                  
                  
                   
                </div>
            </div>
        </div>
    </div>
      )
    
 
  }
}

export default PopUserPermission;