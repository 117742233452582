import React from 'react'
import { Link } from 'react-router-dom'
// import { Link } from 'react-router-dom'

class TableHeader extends React.Component {

    render() {
        return (
            <div className="table-header align-items-center">
                <div>
                    <h5 className="card-title mb-1">{this.props.title}</h5>
                    <p className="text-muted card-sub-title m-0">{this.props.subtitle}</p>
                </div>

                {this.props.view && <button type="button" className="btn ripple btn-primary btn-sm see-all-btn" onClick={this.props.onClick}>See All</button>}

                {this.props.link && <Link to={this.props.link} className="btn ripple btn-primary btn-sm see-all-btn" onClick={this.props.onClick}>See All</Link>}

                {/* <div>
                    <h5 class="card-title mb-2">Stacked Bar Chart</h5>
                    <p class="text-muted card-sub-title card-description">Below is the basic Stacked Bar chart example.</p>
                </div> */}
            </div>

        )
    }

}

export default TableHeader
