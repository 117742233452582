import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import ajax from '../../ajax'
import functions from '../../functions'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import RightSideBar from '../../Components/Common/RightSideBar'
import Footer from '../../Components/Common/Footer'
import PageNav from '../../Components/Common/PageNav'
class DeviceLog extends React.Component {

    state={
        userId:functions.sessionGuard(),
        transactionList:false,
        metaData:false,
        page:1,
        pagination:false
      }

      componentDidMount()
      {
        this.listTransactions();
      }


      DocHeader=()=>{
        
          return(
            <div>
            <h6 className="card-title mb-1">
            Device Log
            </h6>
            <p className="text-muted card-sub-title">All device logs with their corresponding users</p>
          </div>
          )
        
      }


      listTransactions=async()=>{
        const server_response=await ajax.lisDeviceLogs(this.state.page);
        if(server_response.status==="OK")
        {
          this.setState({
                transactionList:server_response.details.list,
                metaData:server_response.details.meta

          }, ()=>this.paginate())

        }else{
          this.setState({
          transactionList:"404"
          })
        }
        
      }


      onClickProfile=(user_id)=>{
        this.props.history.push({
          pathname:'/customer-profile',
          state:{customerId:user_id}
        })
    
      }
      

      onClickAgentProfile=(id)=>{
        this.props.history.push({
          pathname:'/agent-profile',
          state:{agentId:id}
        })
      
      }


     //==============NAVIGATION CONTROLS START===========================
  onClickNext=()=>{
    //increment page numbers
    const metaData=this.state.metaData;
    if(metaData.current_page*1+1<=metaData.total_pages*1)
    {
      this.setState({
        page:this.state.page+1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  onClickPage=(page)=>{
    this.setState({
        page:page,
        transactionList:false
    }, ()=>this.listTransactions())
}

  onClickPrevious=()=>{
    const metaData=this.state.metaData;
    if(metaData.current_page*1>1)
    {
      this.setState({
        page:this.state.page-1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  paginate=()=>{
    if(this.state.metaData)
    {
      this.setState({
        pagination:false
      }, ()=>{

        this.setState({
          pagination:<PageNav
          totalPages={this.state.metaData.total_pages}
          pageList={this.state.metaData.list_of_pages}
          currentPage={this.state.page}
          onClickNext={this.onClickNext}
          onClickPage={this.onClickPage}
          onClickPrevious={this.onClickPrevious}
          />
        })
        
      })
       
    }
  }

  //==============NAVIGATION CONTROLS END===========================

    

    
    render(){
        const list=this.state.transactionList;
          return (
            <div className="page">
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar back={()=>this.props.history.goBack()}/>
                <div className="container-fluid">
                <PageHeader 
                     title="Device Logs"
                     previousPageTitle="Activity"
                     back="/activity"
                  />
                  
                    <div className="row">
                      <div className="col-sm-12 col-xl-12 col-lg-12">
                     
                      <div className="card custom-card">
                          <div className="card-body">
                            <this.DocHeader/>
                            <div className="table-responsive">
                              <table className="table table-bordered text-nowrap mb-0">
                                <thead>
                                    <tr>
                                    <th>Created At</th>
                                    <th>Name</th>
                                    <th>Contact</th>
                                    <th>Role</th>
                                    <th>Device Token</th>
                                   
                                  </tr>
                                </thead>
                                {list && list!=="404" &&  
                                <tbody>
            
                                    {list.map((item, key)=>
                                    <tr key={key}>
                                    <td>{item.date.long_date}</td>
                                    <td>{item.user_info.first_name + ' ' + item.user_info.last_name }</td>
                                      <td>
                                          {item.user_info.username}
                                      </td>
                                    <td>{item.user_info.role_name}</td>
                                    <td>
                                        <span className="d-inline-block text-wrap" style={{maxWidth:'250px'}}>{item.token}</span></td>
                                  </tr>
                                  )}	

                                   
                                  
                                </tbody>}
                              </table>

                              {this.state.pagination}

                                {!this.state.transactionList && <ActivityLoader/>}


                            </div>
                          </div>
                        </div>
                          
                      </div>
                     
                    </div>
                  
                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
     
            
          );  
   
    }
  }
  
  export default DeviceLog
  