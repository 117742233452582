import React from 'react';
import dictionary from '../../dictionary';

class Toast extends React.Component {

    state = {
        status: 'show',
        types: ['bg-success', 'bg-warning', 'bg-danger', 'bg-primary', 'bg-secondary'],
        type: this.props.type || 0,
        message: this.props.message
    }


    componentDidMount() {

        this.timerToast = setTimeout(this.funcToast = () => {
            this.setState({
                status: 'hide',
                message: ''
            }, ()=>{
                this.props.onClose()
            });
            this.timerToast = setTimeout(this.funcToast, dictionary._toastTimeOut); // (*)
        }, dictionary._toastTimeOut);
    }

    // componentWillUnmount() {
    //     clearTimeout(this.timerToast)
    // }



    onClose = () => {
        this.setState({
            status: 'hide'
        }, ()=>this.props.onClose())
    }

    render() {

        return (
            <div className="demo-static-toast pos-absolute t-10 r-10" style={{ zIndex: 10 }}>
                <div aria-atomic="true" aria-live="assertive" className={`toast fade ${this.state.status} custom ${this.state.types[this.state.type]}`} role="alert">
                    <div className="toast-header">
                        <h6 className="tx-14 mg-b-0 mg-r-auto">Notification</h6> &nbsp;&nbsp;&nbsp;&nbsp;<small className="text-muted">Just now</small>
                        <button aria-label="Close"
                            className="ml-2 mb-1 close tx-normal"
                            type="button"
                            onClick={this.onClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="toast-body">
                        {this.props.message}
                    </div>
                </div>
            </div>
        );


    }
}

export default Toast;