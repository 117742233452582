import React from "react";
import dictionary from "../../dictionary";
import functions from "../../functions";
import ajax from "../../ajax";
import ActivityLoader from "../Common/ActivityLoader";
import AlertError from "../Common/AlertError";
import TextInput from "../Common/TextInput";
import AlertSuccess from "../Common/AlertSuccess";

class PopEditMatchName extends React.Component {
  state = {
    userId: functions.sessionGuard(),
    matchName: "",
    info:false,
    matchInfo:this.props.matchInfo,
    loading:false
  };

  componentDidMount() {
    this.setState({
      matchName:this.state.matchInfo.match_name
    })
  }
  
  
  onChangeName = (event) => {
    this.setState({ name: event.target.value });
  };

 
  onConfirm = (event) => {
    event.preventDefault();
   
  };



  render() {
    return (
      <>
        <div className="modal" id="modal_edit_match_name">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modal-content-demo shadow">
              <div className="modal-header">
                <h6 className="modal-title">Add Match</h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {this.state.info}
                <form method="post" onSubmit={this.onConfirm}>
                  <TextInput
                    label="Match name"
                    placeholder="E.g. Arsenal(ENG)"
                    type="text"
                    onChange={() => this.onChangeName}
                    value={this.state.matchName}
                  />
                 


                </form>

              </div>
              {!this.state.loading && (
                <div className="modal-footer">
                  <button
                    className="btn ripple btn-success"
                    type="button"
                    onClick={this.onConfirm}
                  >
                     Save Changes
                  </button>
                  <button
                    className="btn ripple btn-secondary"
                    type="button"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              )}

              {this.state.loading && (
                <div className="modal-footer">
                  <ActivityLoader />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PopEditMatchName;
