import React from 'react';
import ajax from '../../ajax';
import functions from '../../functions';
import ActivityLoader from '../Common/ActivityLoader';
import Toast from '../Common/Toast';

class AccessCodes extends React.Component {

    state={
        userId:functions.sessionGuard(),
        accessInfo:false,
        transactionList:false,
        popApproval:false,
        toast:false
       
    }

  componentDidMount()
  {
    this.requestRecord();
 
  }

  
  copyToClipboard=(text)=>{

    navigator.clipboard.writeText(text)

      this.setState({
          toast:<Toast 
          message={"All Access codes copied to clipboard"} 
          onClose={()=>this.resetToast()}/>
      })
  }
   
  resetToast=()=>{
      
    this.setState({
        toast:false
    })
  }

    readUser=async()=>
    {
        const server_reponse=await ajax.getUserAccess(this.state.userId);
        if(server_reponse.status==="OK")
        {
            this.setState({
               accessInfo:server_reponse.details.list,
            })
        }
    }

    requestRecord=async()=>{
      const server_response=await ajax.listPermissions();
      if(server_response.status==="OK")
      {
        this.setState({
          transactionList:server_response.details
        })
      }else{
          this.setState({
              transactionList:'404'
            })
      }
    }


  


  onClickRecord=(record)=>
  {
       
  }

 
   
  render(){

    const list=this.state.transactionList;
    const codes=[];
    list && list!=="404" && list.map((item, key)=>
        codes.push(item.code)
    )
    
      return (
        <>
        {this.state.toast}
        <div className="card custom-card" id="pill">
								<div className="card-body">
									<div>
										<h6 className="card-title mb-1">Access Codes</h6>
										<p className="text-muted card-sub-title">All system access codes. You can copy these codes when assigning permissions.</p>
									</div>
                  <div className="clipboard-icon" data-clipboard-target="#tags2">
                      <i className="fa fa-clipboard" onClick={()=>this.copyToClipboard(codes)}></i>
                  </div>

									<div className="text-wrap">
                        <figure className="highlight clip-widget">
                
                            <pre>
                                {
                                    list && list!=="404" &&
                                    list.map((item, key)=>
                                    <span key={key} className="pr-1">
                                        {item.code}
                                    </span>
                                    )
                                }
                                
                            </pre>
                        </figure>
									</div>
								</div>
							</div>
        </>
      )
    
 
  }
}

export default AccessCodes;

