import React, { Component } from "react";
import TextInput from "../Common/TextInput";
import ajax from "../../ajax";
import ActivityLoader from "../Common/ActivityLoader";

class PassedTips extends Component {
  state = {
    date: null,
    matches1: false,
    matches1msg: false,
    loading: false,
  };

  componentDidMount() {
    this.BettingPassedTips();
  }

  BettingPassedTips = async () => {
    if (!this.state.date) {

      const server_response = await ajax.BettingPassedTips(this.state.date);
      this.setState({
        loading: false,
      });
      if (server_response.status === "OK") {
        this.setState({
          matches1: server_response.details.tips_list,
        });
      } else {
        this.setState({
          matches1: "404",
          matches1msg: server_response.message,
        });
      }

    } else {
      this.setState({
        loading: true,
      });
      const server_response = await ajax.BettingPassedTips(this.state.date);
      this.setState({
        loading: false,
      });
      if (server_response.status === "OK") {
        this.setState({
          matches1: server_response.details.tips_list,
        });
      } else {
        this.setState({
          matches1: "404",
          matches1msg: server_response.message,
        });
      }
    }

    //console.log(server_response);
  };

  onChangeDate = (event) => {
    this.setState(
      {
        date: event.target.value,
        matches1: false,
        matches1msg: false,
      },
      () => this.BettingPassedTips()
    );
  };
  render() {
    const { matches1 } = this.state;
    return (
      <div className="col-lg-12 col-sm-12">
        <div className="card custom-card">
          <div className="card-body">
            <div>
              <h6 className="card-title mb-1">
                Tips Passed
                <span className="float-right m-10">
                  <TextInput
                    label="Date"
                    type="date"
                    onChange={() => this.onChangeDate}
                    value={this.state.date}
                  />
                </span>
              </h6>
              <p className="text-muted card-sub-title">
                Successfully Passed tips Yesterday
              </p>
            </div>
            <div className="user-manager scroll-widget border-top overflow-auto">
              <div className="table-responsive">
                <table className="table table-bordered table-striped text-nowrap mb-0">
                  <thead>
                    <tr>
                      <th>#No</th>
                      <th>Phone Number</th>
                      <th>Message</th>
                      <th>Price</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {matches1 &&
                      Array.isArray(matches1) &&
                      matches1.map((match, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{match.phone_number}</td>
                          <td style={{ whiteSpace: "pre-wrap", maxWidth: "500px" }}>{match.message_log}</td>
                          <td>
                            {match.price.price_name +
                              " - " +
                              match.price.amount.amount_c}
                          </td>
                          <td>
                            {match.tip_date.short_date +
                              " - " +
                              match.tip_date.time}
                          </td>
                        </tr>
                      ))}
                    {matches1 &&
                      !Array.isArray(matches1) &&
                      this.state.matches1msg}
                  </tbody>
                </table>
                {this.state.loading && <ActivityLoader />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PassedTips;
