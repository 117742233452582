import React from 'react';
import ajax from '../../../ajax';
import functions from '../../../functions';
import DashCard from '../../Common/DashCard';


class TotalPermissionsCard extends React.Component {
  
  state={
    userId:functions.sessionGuard(),
    value:'...'
  }
  componentDidMount()
  {
    this.requestRecord();
  }



  requestRecord=async()=>{
    const server_response=await ajax.countPermissions();
    if(server_response.status==="OK")
    {
      this.setState({
        value:server_response.details.total_c
      })
    }
  }

  render(){
    
      return (
        <DashCard 
            title="Total Permissions"
            value={this.state.value}
            caption="All system permissions"
            />

      );
    
 
  }
}

export default TotalPermissionsCard;