import React from 'react';
import ajax from '../../ajax';
import functions from '../../functions';
import ActivityLoader from '../Common/ActivityLoader';

class ListPermissions extends React.Component {

    state={
        userId:functions.sessionGuard(),
        accessInfo:false,
        transactionList:false,
        popApproval:false,
        search:false
    }

  componentDidMount()
  {
    this.requestRecord();
 
  }

  

   

    readUser=async()=>
    {
        const server_reponse=await ajax.getUserAccess(this.state.userId);
        if(server_reponse.status==="OK")
        {
            this.setState({
               accessInfo:server_reponse.details.list,
            })
        }
    }


    requestRecord=async()=>{
      var server_response;
      if(this.state.search)
      { 
        server_response=await ajax.searchPermission(this.state.search)
        
      }else{
        server_response=await ajax.listPermissions();
      }
      if(server_response.status==="OK")
      {
        this.setState({
          transactionList:server_response.details
        })
      }else{
          this.setState({
              transactionList:'404'
            })
      }
    }


  onChangeHint=(event)=>{
    this.setState({
      search:event.target.value
    }, ()=>{
      if(this.state.search.length==0)
      {
        this.setState({
          search:false
        }, ()=>this.requestRecord())
      }else{
        this.requestRecord()
      }
       
    })
}
   
  render(){

    const list=this.state.transactionList;
    
      return (
        <>
        {this.state.popApproval}
        <div className="card custom-card">
            <div className="card-body">
                <div>
                    <h6 className="card-title mb-1">System Permissions</h6>
                    <p className="text-muted mb-0 card-sub-title">List of all available system permissions</p>
                </div>
                <div class="input-group mt-1">
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Search Permission"
                            onChange={this.onChangeHint}
                            value={this.state.search && this.state.search || ""}
                            />
                       
                    </div>
                   
            </div>
            <div className="user-manager scroll-widget border-top overflow-auto">
                <div className="table-responsive">
                    <table className="table mg-b-0">
                        <tbody>
                           {list && list!=="404" && list.map((item, key)=>
                            <tr key={key} 
                                className="hot-tr"
                               // onClick={()=>this.onClickRecord(item)}
                               >
                               
                                <td className="bd-t-0">
                                    <h6 className="mg-b-0">{item.code}</h6>
                                    <small className="tx-11 tx-gray-500">{item.description}</small>
                                </td>
                                <td>
                                    <a class="main-contact-star" href="#">
                                        <i class="fe fe-edit-2 mr-1"></i>
                                        <i class="fe fe-trash-2 mr-1 text-warning   "></i>
                                    </a>
                                </td>
                              
                            </tr>)}
                           
                        </tbody>
                    </table>
                
                
                {!this.state.transactionList && <ActivityLoader/>}
                
                </div>
            </div>
       
        </div>
        </>
      )
    
 
  }
}

export default ListPermissions;