import React from "react";
import dictionary from "../../dictionary";
import functions from "../../functions";
import ajax from "../../ajax";
import ActivityLoader from "../Common/ActivityLoader";
import AlertError from "../Common/AlertError";
import TextInput from "../Common/TextInput";
import AlertSuccess from "../Common/AlertSuccess";

class PopSetResult extends React.Component {
  state = {
    userId: functions.sessionGuard(),
    result: "",
    info: "",
    loading: false,
    edit: false,
    match_name: ''
  };

  onChangeResult = (event) => {
    this.setState({ result: event.target.value });
  };

  onConfirm = (event) => {
    event.preventDefault();
    this.setState(
      {
        info: "",
      },
      async () => {
        const { result } = this.state;

        if (result) {
          this.setState({
            loading: true,
          });
          const server_response = await ajax.UpdateBettingMatchResult(
            this.props.id,
            result
          );
          this.setState({
            loading: false,
            result: "",
          });
          if ((server_response.status = "OK")) {
            this.setState({
              info: <AlertSuccess message={server_response.message} />,
            });

            this.props.onchange("OK");
          } else {
            this.setState({
              info: <AlertError message={"Failed To Set Result"} />,
            });
          }
        } else {
          this.setState({
            info: <AlertError message={dictionary._completeFields} />,
          });
        }
      }
    );
  };

  updateMatchName = async () => {
    const server_response = await ajax.updateMatchName(this.props.id, this.state.match_name);

    if (server_response.status == 'OK') {
      this.setState({ edit: false }, () => {
        this.props.onrenamematch(this.props.id)
      })
    }
  }

  render() {
    const list = this.props.predictions;
    const matches = this.props.matches;
    return (
      <>
        <div className="modal" id="modal_set_result">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modal-content-demo shadow">
              <div className="modal-header">
                <h6 className="modal-title">Set Result</h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {this.state.info}
                <div>
                  {matches &&
                    Array.isArray(matches) &&
                    matches
                      .filter((match) => match.match_id == this.props.id)
                      .map((match, key) => (
                        <div key={key}>
                          <table className="table">
                            <tr>
                              <td>Name:</td>
                              <td>
                                {!this.state.edit && <b>{match.match_name} <span className="btn btn-primary btn-sm ml-4" onClick={() => this.setState({ match_name: match.match_name, edit: true })}>Edit name</span></b>}
                                {this.state.edit && <b className="d-flex"><input type="text" value={this.state.match_name} onChange={(e) => this.setState({ match_name: e.target.value })} /> <span className="btn btn-success btn-sm ml-4" data-dismiss="modal" onClick={this.updateMatchName}>Save</span></b>}

                              </td>
                            </tr>
                            <tr>
                              <td>Price:</td>
                              <td>
                                <b>
                                  {match.price.price_name +
                                    " - " +
                                    match.price.amount.amount_c}
                                </b>
                              </td>
                            </tr>
                            <tr>
                              <td>Time Played:</td>
                              <td>
                                <b>
                                  {match.match_time.short_date +
                                    " " +
                                    match.match_time.time}
                                </b>
                              </td>
                            </tr>
                            <tr>
                              <td>Description:</td>
                              <td>
                                <b>{match.prediction.prediction}</b>
                              </td>
                            </tr>
                          </table>
                        </div>
                      ))}
                </div>

                <form method="post" onSubmit={this.onConfirm}>
                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-3 text-left">
                      <label class="mg-b-0">Select Result</label>
                    </div>
                    <div class="col-md-9 mg-t-5 mg-md-t-0">
                      <select
                        className="form-control"
                        value={this.state.value}
                        onChange={this.onChangeResult}
                      >
                        <option unselectable>--Select Result--</option>
                        {list &&
                          Array.isArray(list) &&
                          list.map((lis, key) => (
                            <option key={key} value={lis.prediction_id}>
                              {lis.prediction}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </form>

                {/* <p><i>Cash withdrawn fr.</i></p> */}
              </div>
              {!this.state.loading && (
                <div className="modal-footer">
                  <button
                    className="btn ripple btn-success"
                    type="button"
                    onClick={this.onConfirm}
                  >
                    Set Result
                  </button>
                  <button
                    className="btn ripple btn-secondary"
                    type="button"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              )}

              {this.state.loading && (
                <div className="modal-footer">
                  <ActivityLoader />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PopSetResult;
