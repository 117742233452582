import React from 'react'
//const { ipcRenderer } = window.require('electron');

class NoPermission extends React.Component {

   
    onPressLogout=()=>{
        window.localStorage.removeItem('quickpost@user')
        window.location.replace('/');
    }

    
  
    
    render(){
       
     
          return (
            <div className="page error-bg main-error-wrapper">

			<div className="row align-items-center d-flex flex-row">
				<div className="col-lg-6 pr-lg-4 tx-lg-right">
					<h1 className="display-1 mb-0">404</h1>
				</div>
				<div className="col-lg-6 tx-lg-left">
					<h2>Oops.You don't seem to have permissions to access this application</h2>
					<h6>Please contact your system administrator for assistance</h6>
					<button className="btn ripple btn-primary text-center" onClick={this.onPressLogout}>Logout</button>
				</div>
			</div>

		</div>
            
          );  
   
    }
  }
  
  export default NoPermission;
  