import React, { Component } from 'react';
import ajax from '../../ajax';
import ActivityLoader from '../Common/ActivityLoader';

class PhoneValidation extends Component {

    state={
        records:false
    }

    componentDidMount() {
        this.getRecords();
    }
    

    getRecords=async()=>{

        const server_response = await ajax.listRecentPhoneAuth();
        if(server_response.status=="OK")
        {
          this.setState({
            records:server_response.details
          })
        }
  
     }


    render() {
        const listing = this.state.records;
        const list_bg=['bg-secondary', 'bg-success', 'bg-warning', 'bg-danger', 'bg-primary'];
        return (
            <>
            <div className="row row-sm">
                <div className="col-md">
                    <div className="card custom-card">
                        <div className="card-body">
                            <h5 className="card-title tx-dark tx-medium mg-b-10">
                                <i className="fas fa-phone text-success mr-1"/>
                                Phone Validation</h5>
                            <p className="card-subtitle mg-b-15">User validation via phone</p>
                            <div style={{height:"300px"}} className="card-text scroll-widget overflow-auto">
                                    {listing && listing!=="404" &&
                                    <ul class="task-list" style={{lineHeight:1.5}}>
                                       {listing.map((item, key)=>
                                       <li className="pointer" key={key}>
                                       <i class={`task-icon ${list_bg[item.status] || 'bg-light'}`}></i>
                                       <h6>{item.phone}<small class="float-right text-muted tx-11">{item.created_at.short_date + ' ' + item.created_at.time}</small></h6>
                                       <span class="text-muted tx-12 d-inline-block text-truncate" style={{maxWidth: "90%"}}>
                                           {item.code + " - " + item.role}
                                        </span>
                                        </li>)
                                       }
                                    </ul>} 

                                    {!listing && <ActivityLoader/>}                               
                                </div>
                                
                        </div>
                       
                    </div>
                </div>
            </div>
         </>
        )
    }
}

export default PhoneValidation;