import React from 'react';
import ajax from '../../ajax';
import { Link } from 'react-router-dom';
import functions from '../../functions';
import AlertError from '../Common/AlertError';


class ContactSearch extends React.Component {
    state = {
        contact: '',
        tips: false,
        message: "",
        total: false,
        paid: false,
        unpaid: false
    }

    onChangeContact = (e) => {
        this.setState({ message: false, contact: e.target.value });
    }


    searchTips = async (event) => {

        event.preventDefault();
        this.setState({
            paid: 0,
            unpaid: 0,
            total: 0
        })

        if (!this.state.contact) {
            this.setState({ 
                message: <AlertError message="Please enter a phone number"/> 
            })
        }

        const server_response = await ajax.bettingContactSearchMessages(this.state.contact);
        // console.log(server_response)
        if (server_response.status === "OK") {
            this.setState({
                tips: server_response.details.tips,
                total: server_response.details.total,
                paid: server_response.details.paid,
                unpaid: server_response.details.unpaid,
            })
        } else if (server_response.status === "Fail") {
            this.setState({
                tips: '404',
                message: <AlertError message={server_response.message}/>
            })
        }


    }


    render() {
        const { tips } = this.state;
        // console.log(tips)

        return (
            <div className="modal " id="mdl-search">
                <div className="modal-dialog modal-dialog-scrollable modal-lg  modal-dialog-centered" role="document">
                    <div className="modal-content modal-content-demo">
                        <div className="modal-header">
                            <h6 className="modal-title">Contact Search</h6>
                        </div>
                        <div className="modal-body">
                            {this.state.message}
                           <form method='post'>
                            <div className="form-group" onSubmit={this.searchTips}>
                                <input
                                    type="text"
                                    className="form-control input-lg"
                                    placeholder="Enter contact"
                                    value={this.state.contact}
                                    onChange={this.onChangeContact} />
                                <button type='submit' className="btn btn-success btn-lg form-control mt-3" onClick={this.searchTips}>Search</button>
                            </div>
                            </form>

                            <div className="table-responsive">
                                {tips && tips !== '404' &&
                                    <div className="row mt-3">
                                        <div className="col-md-12">
                                            <div className="card custom-card">
                                                <div className="row">

                                                    <div className="col-xl-4 col-lg-4 col-sm-4 pr-0 pl-0 border-right">
                                                        <div className="card-body text-center">
                                                            <h6 className="mb-0">Paid Tips</h6>
                                                            <h3 className="mb-1 mt-2 number-font">{this.state.paid}</h3>

                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-lg-4 col-sm-4 pr-0 pl-0 border-right">
                                                        <div className="card-body text-center">
                                                            <h6 className="mb-0">Unpaid Tips</h6>
                                                            <h3 className="mb-1 mt-2 number-font">{this.state.unpaid}</h3>

                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-lg-4 col-sm-4 pr-0 pl-0 border-right">
                                                        <div className="card-body text-center">
                                                            <h6 className="mb-0">Total Tips</h6>
                                                            <h3 className="mb-1 mt-2 number-font">{this.state.total}</h3>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                }
                                <table className="table mg-b-0 table-bordered">
                                    <tbody>

                                        {tips && tips !== '404' && tips.map((item, key) =>
                                            <tr key={key}>
                                                <td>{item.phone}</td>
                                                <td>{item.price_name}<br />{item.price}</td>
                                                <td>{item.message}</td>
                                                <td>{item.paid === '1' ? "Paid" : "Not Paid"}</td>
                                                <td>{item.date.when}</td>
                                            </tr>
                                        )}

                                    </tbody>
                                </table>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        )
    }

}

export default ContactSearch;