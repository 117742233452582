import React from "react";
import dictionary from "../../dictionary";
import functions from "../../functions";
import ajax from "../../ajax";
import ActivityLoader from "../Common/ActivityLoader";
import AlertError from "../Common/AlertError";
import TextInput from "../Common/TextInput";
import AlertSuccess from "../Common/AlertSuccess";

class PopAddMatch extends React.Component {
  state = {
    userId: functions.sessionGuard(),
    name: "",
    prediction: "",
    price: "",
    date: "",
    time: "",
    info: "",
    odd: "",
    prices: false,
    predictions: false,
    loading: false,
    bettingDate:"",
    bettingTime:""
  };

  componentDidMount() {
    this.BettingPredictionsList();
    this.BettingPricesList();
  }

  BettingPredictionsList = async () => {
    const server_response = await ajax.BettingPredictionsList();
    if (server_response.status === "OK") {
      this.setState({
        predictions: server_response.details,
      });
    } else {
      this.setState({
        predictions: "404",
      });
    }
  };

  BettingPricesList = async () => {
    const server_response = await ajax.BettingPricesList();
    if (server_response.status === "OK") {
      this.setState({
        prices: server_response.details,
      });
    } else {
      this.setState({
        prices: "404",
      });
    }
  };

  onChangename = (event) => {
    this.setState({ name: event.target.value });
  };

  onChangeprediction = (event) => {
    this.setState({ prediction: event.target.value });
  };

  onChangePrice = (event) => {
    this.setState({ price: event.target.value });
  };

  onChangeDate = (event) => {
    this.setState({ date: event.target.value });
  };

  onChangeTime = (event) => {
    this.setState({ time: event.target.value });
  };

  onChangeOdd = (event) => {
    this.setState({ odd: event.target.value });
  };

  onConfirm = (event) => {
    event.preventDefault();
    this.setState(
      {
        info: "",
      },
      async () => {
        const { name, prediction, price, date, time, odd, bettingDate, bettingTime } = this.state;

        if (
          (name.length > 0 && prediction.length > 0 &&
          price > 0 && date.length>0 && time.length>0 && odd.length && bettingDate.length>0 && bettingTime.length>0)
        ) {
          const date_time = date + " " + time;
          const deadline = bettingDate + " " + bettingTime;

          this.setState({
            loading: true,
          });
          const server_response = await ajax.newBettingMatch(
            name,
            price,
            prediction,
            date_time,
            odd,
            deadline
          );
          this.setState({
            loading: false,
          });
          if ((server_response.status = "OK")) {
            this.setState({
              info: <AlertSuccess message="Match  Successfully Added" />,
              date: "",
              time: "",
              name: "",
              prediction: "",
              price: "",
              odd: "",
            });
          } else {
            this.setState({
              info: <AlertError message={"Failed To Add Match"} />,
              date: "",
              time: "",
              name: "",
              prediction: "",
              price: "",
              odd: "",
            });
          }
        } else {
          this.setState({
            info: <AlertError message={dictionary._completeFields} />,
          });
        }
      }
    );
  };


  onChangeBettingDate = (event) => {
    this.setState({ bettingDate: event.target.value });
  };

  onChangeBettingTime = (event) => {
    this.setState({ bettingTime: event.target.value });
  };

  render() {
    const list1 = this.state.prices;
    const list = this.state.predictions;
    return (
      <>
        <div className="modal" id="modal_add_match">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modal-content-demo shadow">
              <div className="modal-header">
                <h6 className="modal-title">Add Match</h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {this.state.info}
                <form method="post" onSubmit={this.onConfirm}>
                  <TextInput
                    label="Match name"
                    placeholder="E.g. Arsenal(ENG)"
                    type="text"
                    onChange={() => this.onChangename}
                    value={this.state.name}
                  />
                  <TextInput
                    label="Match Odd"
                    type="number"
                    placeholder="E.g. 1.5"
                    onChange={() => this.onChangeOdd}
                    value={this.state.odd}
                  />
                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-3 text-left">
                      <label class="mg-b-0">Prediction</label>
                    </div>
                    <div class="col-md-9 mg-t-5 mg-md-t-0">
                      <select
                        className="form-control"
                        value={this.state.prediction}
                        onChange={this.onChangeprediction}
                      >
                        <option unselectable>--Select Prediction--</option>
                        {list &&
                          Array.isArray(list) &&
                          list.map((prediction, key) => (
                            <option key={key} value={prediction.prediction_id}>
                              {prediction.prediction}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-3 text-left">
                      <label class="mg-b-0">Match Price</label>
                    </div>
                    <div class="col-md-9 mg-t-5 mg-md-t-0">
                      <select
                        className="form-control"
                        value={this.state.price}
                        onChange={this.onChangePrice}
                      >
                        <option unselectable>--Select Price--</option>
                        {list1 &&
                          Array.isArray(list1) &&
                          list1.map((price, key) => (
                            <>
                              <option key={key} value={price.price_id}>
                                {price.price_name} - {price.amount.amount_c}
                              </option>
                            </>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-9">
                      <TextInput
                        label="Match Date"
                        type="date"
                        onChange={() => this.onChangeDate}
                        value={this.state.date}
                      />
                    </div>
                    <div className="col-md-3">
                      <TextInput
                        type="time"
                        onChange={() => this.onChangeTime}
                        value={this.state.time}
                      />
                    </div>

                  </div>



                  <div className="row">
                    <div className="col-md-9">
                      <TextInput
                        label="Betting Deadline"
                        type="date"
                        onChange={() => this.onChangeBettingDate}
                        value={this.state.bettingDate}
                      />
                    </div>
                    <div className="col-md-3">
                      <TextInput
                        type="time"
                        onChange={() => this.onChangeBettingTime}
                        value={this.state.bettingTime}
                      />
                    </div>

                  </div>




                </form>

                {/* <p><i>Cash withdrawn fr.</i></p> */}
              </div>
              {!this.state.loading && (
                <div className="modal-footer">
                  <button
                    className="btn ripple btn-success"
                    type="button"
                    onClick={this.onConfirm}
                  >
                    Add match
                  </button>
                  <button
                    className="btn ripple btn-secondary"
                    type="button"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              )}

              {this.state.loading && (
                <div className="modal-footer">
                  <ActivityLoader />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PopAddMatch;
