import React from 'react'
import { Link } from 'react-router-dom'

class DashCard extends React.Component{

    render(){
        return(


            <div className="col-sm-6 col-md-3 col-xl-3 col-lg-6">
                <Link to={this.props.href}>
                    <div className="card custom-card pinned-card" style={{ borderRadius: 10 }}>
                        <div className="card-body dash1">
                            <div className="d-flex">
                                <p className="mb-2 text-muted">{this.props.title}</p>
                                <div className="ml-auto">
                                    <i className={`${this.props.icon} fs-20 ${this.props.color}`}></i>
                                </div>
                            </div>
                            <h3 className="dash-25">{this.props.value}</h3>
                            <div className="progress mb-2">
                                <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="100" className={`progress-bar progress-bar-xs wd-100p ${this.props.progressbar || 'bg-primary'}`} role="progressbar"></div>
                            </div>
                            <div className="expansion-label d-flex">
                                <span className="text-muted">{this.props.caption}</span>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            
           )
    }

}

export default DashCard